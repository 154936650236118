<template>
  <custom-select
      :value="value"
      display-field="short_name"
      :options="paymentMethods"
      :placeholder="placeholder"
      :label="label"
      @input="updateValue"
  >
    <template #option="{item}">
      <div class="d-flex d-inline align-items-center gap-1">
        <img :src="item['logo']" alt="payment method" style="width: 30px"/>
        <div>
          <div><strong>{{ item['short_name'] }}</strong></div>
          <div>{{ item['full_name'] }}</div>
        </div>
      </div>
    </template>
    <template #selected-option="{item}">
      <div class="d-flex d-inline align-items-center gap-1">
        <img :src="item['logo']" alt="payment method" style="width: 20px"/>
        <strong>{{ item['short_name'] }}</strong>
      </div>
    </template>
  </custom-select>
</template>

<script>
import inputHelper from "@/mixins/utils/inputHelper";
import dataObjectList from "@/mixins/utils/dataObjectList";
import dataRequester from "@/mixins/utils/dataRequester";

import publicService from "@/mixins/utils/axios/public.service";
import CustomSelect from "@/views/components/form/CustomSelect.vue";

export default {
  name: "PaymentMethodList",
  mixins: [inputHelper, dataObjectList, dataRequester],
  components: {
    CustomSelect
  },
  props: {
    value: {
      type: [String, Number, Object],
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: "Select payment method",
    },
    items: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      paymentMethods: [],
    }
  },
  watch : {
    items: {
      handler: function (val) {
        this.paymentMethods = val
      },
      deep: true
    }
  },
  created() {
    if ([null, undefined].includes(this.items)) {
      this.getPaymentDetailList()
    } else {
      this.paymentMethods = this.items
    }
  },
  methods: {
    getPaymentDetailList() {
      this.runApi(publicService.getPaymentDetailList(), (res) => {
        this.paymentMethods = res.data
      })
    },
    updateValue(value) {
      this.$emit('input', value)
      this.$emit('change', this.paymentMethods.find(paymentMethod => paymentMethod.id === value))
    }
  }
}
</script>
