<template>
  <div>
    <b-card>
      <b-card-body class="d-flex justify-content-center">
        <b-card-text>
          <b-row>
            <b-col cols="12">
              <b-form-group class="text-center">
                <b-img v-if="qrCodeUrl" :src="qrCodeUrl" fluid/>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <payment-detail-list
                  label=""
                  placeholder="Chọn phương thức thanh toán"
                  :items="paymentDetailList"
                  v-model="currentPaymentMethodIndex"
              />
            </b-col>
            <b-col cols="12">
              <money-box
                  label="Số tiền"
                  v-model="amount"
              />
            </b-col>
            <b-col cols="12">
              <custom-input
                  label="Nội dung"
                  v-model="content"
              />
            </b-col>
            <b-col cols="12">
              <b-form-group class="text-center">
                <b-button @click="generateQrCode" variant="primary">Tạo mã QR</b-button>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card-text>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import inputHelper from "@/mixins/utils/inputHelper";
import dataObjectList from "@/mixins/utils/dataObjectList";
import dataRequester from "@/mixins/utils/dataRequester";
import notificationHelper from "@/mixins/notificationHelper";

import publicService from "@/mixins/utils/axios/public.service";

import CustomInput from "@/views/components/form/CustomInput.vue";
import MoneyBox from "@/views/components/MoneyBox.vue";
import PaymentDetailList from "@/views/components/static/PaymentDetailList.vue";

export default {
  name: 'QrCodeGenerator',
  mixins: [inputHelper, dataObjectList, dataRequester, notificationHelper],
  components: {
    CustomInput,
    MoneyBox,
    PaymentDetailList
  },
  data() {
    return {
      qrCodeUrl: null,
      paymentDetailList: [],
      currentPaymentMethod: null,
      currentPaymentMethodIndex: null,
      amount: 0,
      content: ''
    }
  },
  created() {
    this.getPaymentDetailList()
  },
  methods: {
    getPaymentDetailList() {
      this.runApi(publicService.getPaymentDetailList(), (res) => {
        this.paymentDetailList = res.data.filter(x => x['type'] === 'Bank')
      })
    },
    generateQrCode() {
      if (!this.currentPaymentMethodIndex) {
        this.popupError('Vui lòng chọn phương thức thanh toán')
        return
      }
      this.currentPaymentMethod = this.paymentDetailList.find(x => x['id'] === this.currentPaymentMethodIndex)
      this.qrCodeUrl = `https://img.vietqr.io/image/${this.currentPaymentMethod['short_name']}-${this.currentPaymentMethod['account_number']}-zMyI4c2.png?amount=${this.amount}&addInfo=${this.content}&accountName=${this.currentPaymentMethod['holder']}`
    }
  }
}
</script>
